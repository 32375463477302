import React from "react"

import * as styles from "~styles/atoms/box.module.scss"
import cx from "classnames"
import { Link } from "gatsby"

export const Box = ({
    children,
    className,
    arrow = false,
    padding = false,
    dark = false,
    onClick = false,
    to = false,
    buttonLabel = false,
    gutterBottom = false,
    externalLink = false,
    target = "_blank",
    ...others
}) =>
    React.createElement(
        to && !buttonLabel && !onClick ? "a" : "div",
        {
            className: cx(
                styles.root,
                { [styles.arrow]: arrow },
                { [styles.arrowIsLarge]: arrow && arrow === "large" },
                { [styles.padding]: padding },
                { [styles.dark]: dark },
                { [styles.gutterBottom]: gutterBottom },
                { [styles.onClick]: onClick },
                className
            ),
            onClick: onClick ? onClick : () => {},
            href: to && !buttonLabel && !onClick ? to : undefined,
            target: to && !buttonLabel && !onClick ? target : undefined,
            ...others,
        },
        <>
            <div className={styles.content}>{children}</div>
            {!externalLink && to && buttonLabel && (
                <Link to={to} className={cx(styles.button)}>
                    {buttonLabel}
                </Link>
            )}
            {externalLink && to && buttonLabel && (
                <a target={target} href={to} className={cx(styles.button)}>
                    {buttonLabel}
                </a>
            )}
        </>
    )
