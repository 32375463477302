import React from "react"
import { graphql } from "gatsby"
import { DefaultLayout } from "~components/layout/Default"
import { SEO } from "~components/Seo"
import { GridColumn, GridRow } from "~components/atoms/Grid"
import { Headline } from "~components/atoms/Headline"
import { RteText } from "~components/atoms/RteText"
import { ContactBox } from "~components/elements/ContactBox"

import * as styles from "~styles/templates/news.module.scss"
import * as buttonStyles from "~styles/atoms/button.module.scss"
import { Box } from "~components/atoms/Box"
import { translate } from "~utility/Sink"
import { LocalizedDate } from "~components/atoms/LocalizedDate"

import cx from "classnames"
import { trackFileDownload } from "../utility/tracking"

const NewsTemplate = ({ data }) => {
    const news = data.news.data

    const { lang, type, url } = data.news || {}
    const alternateLanguages = data.news.alternate_languages || []
    const activeDoc = {
        lang,
        type,
        url,
        alternateLanguages,
    }

    const contact = data.contact.edges.find(item => item.node.lang === lang)
        .node.data
    const settings = data.settings.edges.find(item => item.node.lang === lang)
        .node.data

    return (
        <DefaultLayout
            variant="dark"
            activeDocMeta={activeDoc}
            title={news.name.text}
        >
            <SEO
                title={news.name.text}
                description={news.description.text}
                lang={lang}
            />

            <div className={styles.root}>
                <GridRow width="content">
                    <GridColumn largeSpacing s={12} m={8} l={8}>
                        <time>
                            <LocalizedDate date={new Date(news.date)} />
                        </time>
                        <Headline variant="h2">{news.name.text}</Headline>
                        {news.image?.url && (
                            <img
                                className={styles.image}
                                src={news.image.url}
                            />
                        )}
                        <RteText
                            content={news.text.html}
                            gutterBottom="large"
                        />
                        {news.type === "Ad-hoc Meldungen" && (
                            <RteText
                                gutterBottom="large"
                                content={settings.footer_ad_hoc_release.html}
                            />
                        )}
                        {news.type === "Pressemitteilungen" && (
                            <RteText
                                gutterBottom="large"
                                content={settings.footer_press_release.html}
                            />
                        )}
                        {news.type === "Corporate News" && (
                            <RteText
                                gutterBottom="large"
                                content={settings.footer_corporate_news.html}
                            />
                        )}
                    </GridColumn>
                    <GridColumn largeSpacing s={12} m={6} l={4}>
                        <ContactBox
                            language={lang}
                            item={{
                                headline: contact.name.text,
                                subline: contact.title.text,
                                address: contact.address.html,
                                phone: contact.telephone.text,
                                email: contact.email.text,
                            }}
                        />
                        {news.downloads &&
                            news.downloads
                                .filter(item => item.download.document?.data)
                                .map(download => (
                                    <Box
                                        to={
                                            download.download.document.data.file
                                                .url
                                        }
                                        onClick={() => {
                                            trackFileDownload(
                                                download.download.document.data
                                                    .file.url,
                                                download.download.document.data
                                                    .file.size
                                            )
                                        }}
                                        buttonLabel={translate(
                                            "newsroom.download_as_pdf",
                                            lang
                                        )}
                                        padding
                                        externalLink
                                        gutterBottom
                                    >
                                        <Headline variant="h3">
                                            {
                                                download.download.document.data
                                                    .name.text
                                            }
                                        </Headline>
                                    </Box>
                                ))}

                        {news.pdf.url && (
                            <Box
                                to={news.pdf.url}
                                buttonLabel={translate(
                                    "newsroom.download_as_pdf",
                                    lang
                                )}
                                padding
                                externalLink
                                gutterBottom
                            >
                                <Headline variant="h3">
                                    {news.type === "Corporate News" && (
                                        <span>
                                            {translate(
                                                "newsroom.headline.corporate_news",
                                                lang
                                            )}
                                        </span>
                                    )}
                                    {news.type === "Ad-hoc Meldungen" && (
                                        <span>
                                            {translate(
                                                "newsroom.headline.ad_hoc_release",
                                                lang
                                            )}
                                        </span>
                                    )}
                                </Headline>
                            </Box>
                        )}
                    </GridColumn>
                </GridRow>
            </div>
        </DefaultLayout>
    )
}

export default NewsTemplate

export const query = graphql`
    query News($uid: String!, $lang: String!) {
        settings: allPrismicSettings {
            edges {
                node {
                    lang
                    data {
                        articles_of_incorporation {
                            url
                        }
                        footer_ad_hoc_release {
                            html
                        }
                        footer_corporate_news {
                            html
                        }
                        footer_press_release {
                            html
                        }
                        meta_description
                        meta_title
                        social_media {
                            name
                            link {
                                url
                            }
                            icon {
                                url
                            }
                        }
                    }
                }
            }
        }
        contact: allPrismicContact(
            filter: { data: { role: { eq: "Investor Relations" } } }
        ) {
            edges {
                node {
                    lang
                    data {
                        address {
                            html
                        }
                        email {
                            text
                        }
                        name {
                            text
                        }
                        role
                        telephone {
                            text
                        }
                        title {
                            text
                        }
                    }
                }
            }
        }
        news: prismicNews(uid: { eq: $uid }, lang: { eq: $lang }) {
            lang
            alternate_languages {
                uid
                type
                lang
                url
            }
            data {
                name {
                    text
                }
                type
                top_news
                text {
                    html
                }
                downloads {
                    download {
                        document {
                            ... on PrismicDownload {
                                id
                                lang
                                data {
                                    name {
                                        text
                                    }
                                    image {
                                        url
                                    }
                                    file {
                                        url
                                        size
                                    }
                                }
                            }
                        }
                    }
                }
                products {
                    product {
                        uid
                        type
                        document {
                            ... on PrismicProduct {
                                id
                                data {
                                    name {
                                        text
                                    }
                                }
                            }
                        }
                    }
                }
                pdf {
                    url
                }
                image {
                    url
                }

                description {
                    text
                }
                date
            }
            uid
            lang
        }
    }
`
